import { Link } from 'react-router-dom'

import JoinForm from './JoinForm'
import System from '../Layout/System'

function Join() {
  return (
    <System>
      <h1>Join to Us</h1>
      <JoinForm />
      <p>
        <Link to="/">Back to home</Link>
      </p>
    </System>
  )
}

export default Join
