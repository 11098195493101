import React from 'react'

import * as cookie from 'cookie'
import ReactDOM from 'react-dom/client'

import './index.css'
import App from './App'
import defaultFeatures from './features'
import { mergeFeatures } from './FeatureToggle'
import reportWebVitals from './reportWebVitals'

const cookies = cookie.parse(document.cookie)
const cookieFeatures = (cookies.features || '').split(/\s*,\s*/g).filter(Boolean)

const features = mergeFeatures(defaultFeatures, cookieFeatures)

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <App features={features} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
