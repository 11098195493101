import { useContext } from 'react'

import AuthContext, { AuthContextValue } from './AuthContext'
export default function useAuth(): AuthContextValue {
  const ctx = useContext(AuthContext)
  if (ctx === null) {
    throw new Error('Unable to use auth outside of provider.')
  }
  return ctx
}
