type Props = {
  label: string
  htmlFor: string | null
}

function InputLabel({ label, htmlFor = null, ...rest }: Props) {
  return (
    <label className="input-label" htmlFor={htmlFor ?? undefined} {...rest}>
      {label}
    </label>
  )
}

export default InputLabel
